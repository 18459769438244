import React from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
const Faq6 = () => {
  return (
    <>
      <section className="faq">
        <div className="container">
        <div className="titlebox">
              <h2>F.A.Q.</h2>
              {/* <h2 >
                Boost Your Rewards Experience with Seamless, Secure Digital
                Asset Integration.
              </h2> */}
            </div>
          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
              Are there multiple pools for staking DWC?
              </CAccordionHeader>
              <CAccordionBody>
              - Yes, various pools are available for different staking durations.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>
                {" "}
                What's the penalty for unstaking before maturity?
              </CAccordionHeader>
              <CAccordionBody>
              - A 10% deduction will be applied to the staked amount.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>
              Is there a lock-in period post-maturity for claimed stakes?
              </CAccordionHeader>
              <CAccordionBody>
             - Yes there's a 7 day locking period after maturity.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>
              
How do I choose the best staking pool?
              </CAccordionHeader>
              <CAccordionBody>
              - Evaluate based on your investment goals and the pool's returns.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={5}>
              <CAccordionHeader>
                {" "}
               
{/* Are there referral bonuses in DWC Staking?
              </CAccordionHeader>
              <CAccordionBody>
              - Yes, there are three levels: 1st at 10%, 2nd at 5%, and 3rd at 2%.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={6}>
              <CAccordionHeader>
                {" "} */}
               
                What tokens do I receive when I stake DWC?
              </CAccordionHeader>
              <CAccordionBody>
              - You will be rewarded with DWC tokens.
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </div>
      </section>
    </>
  );
};

export default Faq6;
