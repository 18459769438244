import {
  graphQlUrl,
  CoinAddress,
  USDT,
  PoolDWC7,
  PoolDWC30,
  PoolDWC90,
  PoolDWC180,
  PoolDWC360,
  PoolUSDT7,
  PoolUSDT30,
  PoolUSDT90,
  PoolUSDT180,
  PoolUSDT360,
} from "../config";
import axios from "axios";
import { ethers } from "ethers";



export const AnalyticsData = async (tokenStr, daysStr) => {
  let poolAddress = getPoolAddress(tokenStr, daysStr);
  poolAddress = poolAddress.toLowerCase();
  //   console.log("poolAddress : ", poolAddress);

  let data = [];
  let flag = true;
  let skip = 0;

  while (flag) {
    const options = {
      method: "POST",
      url: graphQlUrl,
      headers: {
        "content-type": "application/json",
      },
      data: {
        query: `{
                userStakedDatas (
                  first:50,
                  skip:${skip},
                  where: {poolAddress: "${poolAddress}", DaysToMature: "${daysStr}"},
                  orderBy: blockTimestamp,
                   orderDirection: desc
                   ){
                  user
                  amount
                  DaysToMature
                  percentageReturn
                  poolAddress
                  blockTimestamp
                  transactionHash
                }
              }`,
      },
    };
    const response = await axios.request(options);
    const res = response.data.data.userStakedDatas;

    if (res.length == 50) {
      skip += 50;
      data = data.concat(res);
    } else {
      data = data.concat(res);
      flag = false;
    }
  }

  return data;
};

export const get24hrsPoolStakeAmount = async (tokenStr, daysStr) => {
  let data = await AnalyticsData(tokenStr, daysStr);
  let totalStake = 0;
  let currentTime = Date.now();
  let time24hrs = currentTime - 86400000;
  time24hrs = time24hrs / 1000;
  data.forEach((element) => {
    if (parseInt(element.blockTimestamp) > time24hrs) {
      if (tokenStr === "USDT")
        element.amount = ethers.utils.formatUnits(element.amount, 6);
      else element.amount = ethers.utils.formatEther(element.amount);
      totalStake += parseFloat(element.amount);
    }
  });
  return totalStake;
};

export const getTotalPoolStakeAmount = async (tokenStr, daysStr) => {
  let data = await AnalyticsData(tokenStr, daysStr);
  let totalStake = 0;
  data.forEach((element) => {
    if (tokenStr === "USDT")
      element.amount = ethers.utils.formatUnits(element.amount, 6);
    else element.amount = ethers.utils.formatEther(element.amount);
    totalStake += parseFloat(element.amount);
  });
  return totalStake;
};



// Payout History Data from here...

export const PayoutHistoryData = async (tokenStr, daysStr) => {
  let poolAddress = getPoolAddress(tokenStr, daysStr);
  poolAddress = poolAddress.toLowerCase();

  let rewardClaimedData = [];
  let referalRewardEarned = [];
  let flag1 = true;
  let flag2 = true;
  let skip1 = 0;
  let skip2 = 0;

  while (flag1) {
    const options = {
      method: "POST",
      url: graphQlUrl,
      headers: {
        "content-type": "application/json",
      },
      data: {
        query: `{
                rewardClaimeds (
                  first:50,
                  skip:${skip1},
                  where: {poolAddress: "${poolAddress}", daysToMature: "${daysStr}"},
                  orderBy: blockTimestamp,
                   orderDirection: desc
                   ){
                  claimedAmount
                  blockTimestamp
                }
              }`,
      },
    };

    const response = await axios.request(options);
    // console.log("flag 1 : ", response.data.data);
    const res = response.data.data.rewardClaimeds;

    if (res.length == 50) {
      skip1 += 50;
      rewardClaimedData = rewardClaimedData.concat(res);
    } else {
      rewardClaimedData = rewardClaimedData.concat(res);
      flag1 = false;
    }
  }

  if (rewardClaimedData.length > 0) {
    rewardClaimedData = rewardClaimedData.map((item) => {
      return {
        amount: item.claimedAmount,
        blockTimestamp: item.blockTimestamp,
      };
    });
  }

  while (flag2) {
    const options = {
      method: "POST",
      url: graphQlUrl,
      headers: {
        "content-type": "application/json",
      },
      data: {
        query: `{
                referalLevelEarneds (
                  first:50,
                  skip:${skip2},
                  where: {poolAddress: "${poolAddress}"},
                  orderBy: blockTimestamp,
                   orderDirection: desc
                   ){
                  amount
                  blockTimestamp
                }
              }`,
      },
    };

    const response = await axios.request(options);
    // console.log('flag 2 : ',response.data.data)
    const res = response.data.data.referalLevelEarneds;

    if (res.length == 50) {
      skip2 += 50;
      referalRewardEarned = referalRewardEarned.concat(res);
    } else {
      referalRewardEarned = referalRewardEarned.concat(res);
      flag2 = false;
    }
  }
  return rewardClaimedData.concat(referalRewardEarned);
};

export const get24hrsPayoutAmount = async (tokenStr, daysStr) => {
  let data = await PayoutHistoryData(tokenStr, daysStr);
  let totalPayout = 0;
  let currentTime = Date.now();
  let time24hrs = currentTime - 86400000;
  time24hrs = time24hrs / 1000;
  data.forEach((element) => {
    if (parseInt(element.blockTimestamp) > time24hrs) {
      if (tokenStr === "USDT")
        element.amount = ethers.utils.formatUnits(element.amount, 6);
      else element.amount = ethers.utils.formatEther(element.amount);
      totalPayout += parseFloat(element.amount);
    }
  });
  return totalPayout;
};

export const getTotalPayoutAmount = async (tokenStr, daysStr) => {
  let data = await PayoutHistoryData(tokenStr, daysStr);
  let totalPayout = 0;

  data.forEach((element) => {
    if (tokenStr === "USDT")
      element.amount = ethers.utils.formatUnits(element.amount, 6);
    else element.amount = ethers.utils.formatEther(element.amount);
    totalPayout += parseFloat(element.amount);
  });
  return totalPayout;
};




// Table Data from here...


export const AdminReport2TableData = async (tokenStr) => {
  let data = [];
  let flag = true;
  let skip = 0;
  
  let decimals = 18;
  if (tokenStr === "USDT") decimals = 6;

  let tokenPools = staticPoolDetails
    .filter((item) => item.coin === tokenStr)
    .map((item) => item.pool);

  // console.log("Token Pools : ", tokenPools);
  // const tokenPoolsString = JSON.stringify(tokenPools).replace(/"/g, '\\"'); // Escape double quotes for GraphQL query

  // console.log("Token Pools String: ", tokenPoolsString);
const tokenPoolsGraphQLArray =
  "[" + tokenPools.map((pool) => `"${pool}"`).join(",") + "]";

  while (flag) {
    const options = {
      method: "POST",
      url: graphQlUrl,
      headers: {
        "content-type": "application/json",
      },
      data: {
        query: `{
                stakeds (
                  first:50,
                  skip:${skip},
                  orderBy: blockTimestamp,
                   orderDirection: desc
                 where:{
                    poolAddress_in: ${tokenPoolsGraphQLArray} 
                   }
                   )
                   {
                  user
                  amount
                  pool
                  poolAddress
                  percentageReturn
                  blockTimestamp
                  transactionHash
                }
              }`,
      },
    };
    const response = await axios.request(options);
    const res = response.data.data.stakeds;

    for (let i = 0; i < res.length; i++) {
      res[i].percentageReturn = parseFloat(res[i].percentageReturn) / 10000;
      res[i].amount = ethers.utils.formatUnits(res[i].amount,decimals);
      res[i].expiryTimestamp = getExpiryDate(
        res[i].blockTimestamp,
        res[i].pool
      );
      res[i].referalWallet = await getReferalWallet(res[i].transactionHash, 1);
      res[i].totalReward = await getTotalReward(
        res[i].amount,res[i].percentageReturn
      );
    }

    if (res.length == 50) {
      skip += 50;
      data = data.concat(res);
    } else {
      data = data.concat(res);
      flag = false;
    }
  }
  return data;
};

const getTotalReward = async (amount, percentageReturn) => {
  let totalReward = 0;
  amount = parseFloat(amount);
  totalReward = (amount * percentageReturn) / 100;
  return totalReward;
};


const getExpiryDate = (stakeTimestamp, daysStr) => {
  stakeTimestamp = parseInt(stakeTimestamp);
  const stakeDate = new Date(stakeTimestamp * 1000);
  const daysToAdd = parseInt(daysStr, 10);
  stakeDate.setDate(stakeDate.getDate() + daysToAdd);
  const expiryTimestamp = Math.floor(stakeDate.getTime() / 1000);
  return expiryTimestamp;
};

const getReferalWallet = async (txHash) => {
  let referalWallet = ethers.constants.AddressZero;

  const options = {
    method: "POST",
    url: graphQlUrl,
    headers: {
      "content-type": "application/json",
    },
    data: {
      query: `{
              newUserLevelDatas(
                where:{
                  transactionHash: "${txHash}"
                  level: "1"
                },
                ){
                referal
              }
            }`,
    },
  };

  const response = await axios.request(options);
  const res = response.data.data.newUserLevelDatas;
  if (res.length == 1) {
    referalWallet = res[0].referal;
  }

  return referalWallet;
};

const getPoolAddress = (tokenStr, daysStr) => {
  tokenStr = `Pool${tokenStr}${daysStr}`;
  //   console.log(tokenStr);

  let poolAddress = "";

  if (tokenStr === "PoolDWC7") {
    poolAddress = PoolDWC7;
  } else if (tokenStr === "PoolDWC30") {
    poolAddress = PoolDWC30;
  } else if (tokenStr === "PoolDWC90") {
    poolAddress = PoolDWC90;
  } else if (tokenStr === "PoolDWC180") {
    poolAddress = PoolDWC180;
  } else if (tokenStr === "PoolDWC360") {
    poolAddress = PoolDWC360;
  } else if (tokenStr === "PoolUSDT7") {
    poolAddress = PoolUSDT7;
  } else if (tokenStr === "PoolUSDT30") {
    poolAddress = PoolUSDT30;
  } else if (tokenStr === "PoolUSDT90") {
    poolAddress = PoolUSDT90;
  } else if (tokenStr === "PoolUSDT180") {
    poolAddress = PoolUSDT180;
  } else if (tokenStr === "PoolUSDT360") {
    poolAddress = PoolUSDT360;
  }
  return poolAddress;
};

const staticPoolDetails = [
  { pool: PoolDWC7, percent: 6.39, days: 7, coin: "DWC", label: "DWC-7" },
  { pool: PoolDWC30, percent: 8.21, days: 30, coin: "DWC", label: "DWC-30" },
  { pool: PoolDWC90, percent: 11.01, days: 90, coin: "DWC", label: "DWC-90" },
  {
    pool: PoolDWC180,
    percent: 14.21,
    days: 180,
    coin: "DWC",
    label: "DWC-180",
  },
  {
    pool: PoolDWC360,
    percent: 18.08,
    days: 360,
    coin: "DWC",
    label: "DWC-360",
  },
  { pool: PoolUSDT7, percent: 4.25, days: 7, coin: "USDT", label: "USDT-7" },
  { pool: PoolUSDT30, percent: 6.39, days: 30, coin: "USDT", label: "USDT-30" },
  { pool: PoolUSDT90, percent: 8.21, days: 90, coin: "USDT", label: "USDT-90" },
  {
    pool: PoolUSDT180,
    percent: 10.08,
    days: 180,
    coin: "USDT",
    label: "USDT-180",
  },
  {
    pool: PoolUSDT360,
    percent: 12.01,
    days: 360,
    coin: "USDT",
    label: "USDT-360",
  },
];
