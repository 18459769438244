import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import icon1 from "../Assets/images/2high-return.png";
import icon3 from "../Assets/images/2reduce-penalties.png";
import icon4 from "../Assets/images/2referral-program (1).png";
import Banner from "../Components/Banner";
import StakeList from "../Components/StakeList";
import FAQSection from "../Components/Fqsection";
import Helpbtn from '../Components/Helpbutton/Helpbtn'

const Home = () => {
  return (
    <>
      <Banner />
      <StakeList />
      <section className="informationMU py-5">
        <CContainer>
          <div className="titlebox">
            <h2>Benefits of Staking DWC Money:</h2>
          </div>
          <CRow>
            <CCol lg="4" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon1} alt="Safe and Secure" />
                </div>

                <h4>High Returns:</h4>
                <p>
                  Enhance your profits by staking DEFI World Tokens (DWC) in
                  diverse pools offering attractive interest rates.
                </p>
              </div>
            </CCol>
          
            <CCol lg="4" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon3} alt="Safe and Secure" />
                </div>

                <h4> Minimal Penalties:</h4>
                <p>
                  For early withdrawals before the completion of the Staking
                  term, incur only a modest penalty, significantly lower than
                  the industry norm.
                </p>
              </div>
            </CCol>
            <CCol lg="4" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon4} alt="Safe and Secure" />
                </div>

                <h4> Rewarding Referral Program:</h4>
                <p>
                  Take advantage of our multi-tiered referral system. Earn 
                   monthly from your direct referrals and additional rewards
                  from second and third-tier referrals in the DEFI World
                  ecosystem.
                </p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>
      {/* <Helpbtn /> */}
      <FAQSection />
    </>
  );
};

export default Home;
