
function dateCon(params) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate

}



function maturityDate(params, daysToAdd) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);

    // Add the specified number of days
    date.setDate(date.getDate() + daysToAdd);

    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

    return formattedDate;
}






export {
    dateCon,
    maturityDate,
}
