import React, { useRef, useState } from "react";
import "../Assets/css/Admin.css";
import { AdminFunction, ContractBalance, GetCoinByPool, ReferralOuter, UsePoolData } from "../hooks/useContractData";
import { toLocale } from "../hooks/useContract";
import {
  PoolDWC180,
  PoolDWC30,
  PoolDWC360,
  PoolDWC7,
  PoolDWC90,
  PoolUSDT180,
  PoolUSDT30,
  PoolUSDT360,
  PoolUSDT7,
  PoolUSDT90,
  CoinAddress,
  USDT,
} from "../config";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MenuItem, Select } from "@mui/material";


const LiquidityForm = ({
  poolAddress,
  actionLabel,
  actionFunction,
  placeholder,
  enable
}) => (
  <div className="os_row align-items-center boxpart">
    <div className="leftbx">
      <strong style={{ display: "flex", flexWrap: "wrap" }}>
        {actionLabel}{" "}
      </strong>
    </div>

    <div className="inputbox">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          actionFunction(e.target.token.value, e.target.amount.value, poolAddress);
        }}
      >
        <input
          className="inputswap"
          type="text"
          name="amount"
          autoComplete="off"
          placeholder={placeholder}
        />
        <select className="inputswapbtn" name="token" id="">
          <option value={CoinAddress}>DWC</option>
          <option value={USDT}>USDT</option>
        </select>
        <button type="submit" disabled={enable}>Submit</button>
      </form>
    </div>
  </div>
);


const AdminPanel = () => {
  const { AddLiquidity, EmergencyWithdraw, SetpercentageReturn, SetReferralPercentage, enable } = AdminFunction();
  const { referralArray } = ReferralOuter()
  const { balance, balance2 } = ContractBalance();
  const [value1, setValue1] = useState("DWC");
  const [value, setValue] = useState(PoolDWC7);
  const [pool, setPool] = useState(PoolDWC7);
  const [level, setLevel] = useState(0);
  const selectRef = useRef(null);
  const { poolDetails } = UsePoolData()


  const handleChange = (event, newValue) => {
    setPool(newValue);
    setValue(newValue);
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    if (newValue === "DWC") {
      setValue(PoolDWC7);
      setPool(PoolDWC7);
    } else {
      setValue(PoolUSDT7);
      setPool(PoolUSDT7);
    }
  };


  return (
    <>
      <section className="mainbox adminform">
        <div className="os_container">
          <div className="tabsection">

            <div className="tabbar2 flex">
              <Tabs
                value={value1}
                onChange={handleChange1}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
              >
                <Tab label="DWC" value={"DWC"} />
                <Tab label="USDT" value={"USDT"} />
              </Tabs>
            </div>
            {value1 === "DWC" ? (
              <div className="tabbar2 flex">
                <Tabs
                  value={value}
                  ref={selectRef}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                >
                  <Tab label="DWC-7" value={PoolDWC7} />
                  <Tab label="DWC-30" value={PoolDWC30} />
                  <Tab label="DWC-90" value={PoolDWC90} />
                  <Tab label="DWC-180" value={PoolDWC180} />
                  <Tab label="DWC-360" value={PoolDWC360} />
                </Tabs>
              </div>
            ) : (
              <div className="tabbar2 flex">
                <Tabs
                  value={value}
                  ref={selectRef}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                >
                  <Tab label="USDT-7" value={PoolUSDT7} />
                  <Tab label="USDT-30" value={PoolUSDT30} />
                  <Tab label="USDT-90" value={PoolUSDT90} />
                  <Tab label="USDT-180" value={PoolUSDT180} />
                  <Tab label="USDT-360" value={PoolUSDT360} />
                </Tabs>
              </div>
            )}
          </div>

          <div className="boxmain">
            <div className="boxinner">
              <h5 className="mb-3 "> Pool : {GetCoinByPool(poolDetails, pool).label}</h5>
              <span className="d-block mb-2" style={{ color: "#00FFFF", fontSize: "16px" }}>
                Bal: {toLocale(balance[pool] || '0')} DWC
              </span>
              <span className="d-block mb-2" style={{ color: "#00FFFF", fontSize: "16px" }}>
                Bal: {toLocale(balance2[pool] || '0')} USDT
              </span>
              <LiquidityForm
                poolAddress={pool}
                actionLabel={`Add Liquidity`}
                actionFunction={AddLiquidity}
                placeholder={"Enter Amount"}
                enable={enable}
              />
              <LiquidityForm
                poolAddress={pool}
                actionLabel={`Withdraw`}
                actionFunction={EmergencyWithdraw}
                placeholder={"Enter Amount"}
                enable={enable}

              />
              {/* <LiquidityForm
                poolAddress={pool}
                actionLabel={`Set ARP (%)`}
                actionFunction={SetpercentageReturn}
                placeholder={`Current ${GetCoinByPool(poolDetails, pool).percent} %`}
                enable={enable}

              /> */}

            </div>
          </div>
        </div>
        <div className="os_container mt-5">

          {/* <div className="boxmain">
            <div className="boxinner">
              <h5 className="mb-3 "> Set Referral Percentage</h5>
              <div className="os_row align-items-center boxpart">
                <div className="leftbx">
                  <strong style={{ display: "flex", flexWrap: "wrap" }}>
                    Select Level
                  </strong>
                </div>

                <div className="inputbox">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      SetReferralPercentage(e.target.level.value, e.target.percent.value);
                    }}
                    className="flex-column"
                  >
                    <Select
                      className="inputswap w-100"
                      name="level"
                      defaultValue={0}
                      onChange={(e)=>setLevel(e.target.value)}
                    >
                      <MenuItem value={0}>1</MenuItem >
                      <MenuItem value={1}>2</MenuItem >
                      <MenuItem value={2}>3</MenuItem >
                    </Select>

                    <div className="leftbx">
                      <strong style={{ display: "flex", flexWrap: "wrap" }}>
                        Set Percentage(%)
                      </strong>
                    </div>

                    <input
                      className="inputswap"
                      type="text"
                      name="percent"
                      autoComplete="off"
                      placeholder={`Current ${referralArray[level]} %`}
                    />
                    <button type="submit" disabled={enable}>Submit</button>
                  </form>
                </div>
              </div>

            </div>
          </div> */}
        </div>
      </section>



    </>
  );
};

export default AdminPanel;
