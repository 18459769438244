import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import { get24hrsPoolStakeAmount,getTotalPoolStakeAmount } from "../../hooks/adminReport2Graph";
import { get } from 'animejs';

export default function Analytics() {
  const [selectedPool, setSelectedPool] = useState("DWC");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("7");

  const [analyticsData, setAnalyticsData] = useState([0,0]);

  const handleChangePool = (event, newValue) => {
    setSelectedPool(newValue);
    // When changing pool, reset the time period to "7"
    setSelectedTimePeriod("7");
  };

  const handleChangeTimePeriod = (event, newValue) => {
    setSelectedTimePeriod(newValue);
  };

  // Synchronize time period with pool selection
  useEffect(() => {
    setSelectedTimePeriod("7"); // Reset time period to "7" whenever pool changes
  }, [selectedPool]);

  // Define card box content based on selected pool and time period
  const cardBoxContent = {
    "DWC": {
      "7": "DWC Staked in 24hrs.",
      "30": "DWC Staked in 24hrs.",
      "60": "DWC Staked in 24hrs.",
      "90": "DWC Staked in 24hrs.",
      "180": "DWC Staked in 24hrs.",
      "360": "DWC Staked in 24hrs.",
   
    },
    "USDT": {
      "7": "USDT Staked in 24hrs.",
      "30": "USDT Staked in 24hrs.",
      "60": "USDT Staked in 24hrs.",
      "90": "USDT Staked in 24hrs.",
      "180": "USDT Staked in 24hrs.",
      "360": "USDT Staked in 24hrs.",
  
    },
  };
  const cardBoxContent2 = {
    "DWC": {
      "7": "Total DWC Staked",
      "30": " Total DWC Staked",
      "60": " Total DWC Staked",
      "90": " Total DWC Staked",
      "180": "Total DWC Staked",
      "360": "Total DWC Staked",
   
    },
    "USDT": {
      "7": "Total USDT Staked",
      "30": "Total USDT Staked",
      "60": "Total USDT Staked",
      "90": "Total USDT Staked",
      "180": "Total USDT Staked",
      "360": "Total USDT Staked",

    },
  };

  // useEffect(()=>{
  //   get24hrsPoolStakeAmount("DWC", "360");
  //   getTotalPoolStakeAmount("DWC", "360");
  // },[])

  useEffect(() => {

    let handleAnalyticsData = async () => {
      let totalStake = await get24hrsPoolStakeAmount(selectedPool, selectedTimePeriod);
      let totalStake2 = await getTotalPoolStakeAmount(selectedPool, selectedTimePeriod);
      setAnalyticsData([totalStake, totalStake2]);
    }

    handleAnalyticsData();

  }, [selectedPool, selectedTimePeriod]);

  // useEffect(()=>{
  //      console.log('analyticsData : ',analyticsData)
  // },[analyticsData])

  return (
      <section className="boxsmain" style={{ marginTop: 100, marginBottom: 40 }}>
        <div className="os_container mt-5">
          
          <div className="titleh2 mt-5 ">
            <h2>Analytics </h2>
          </div>

          <div className=" flex justify-content-center mt-5 mb-5">
            <Tabs
              value={selectedPool}
              onChange={handleChangePool}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
            >
              <Tab label="DWC" value="DWC" />
              <Tab label="USDT" value="USDT" />
            </Tabs>
          </div>

          <div className=" flex justify-content-center mb-5">
            <Tabs
              value={selectedTimePeriod}
              onChange={handleChangeTimePeriod}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
            >
              <Tab label="7 Days" value="7" />
              <Tab label="30 Days" value="30" />
              <Tab label="60 Days" value="60" />
              <Tab label="90 Days" value="90" />
              <Tab label="180 Days" value="180" />
              <Tab label="360 Days" value="360" />
             
            </Tabs>
          </div>

          {/* Render card box content */}
          <div className='os_row'>
            <div className="col4">
              <div className="cardbox">
                <p>{cardBoxContent[selectedPool][selectedTimePeriod]}</p>
                <p>{analyticsData[0]} {selectedPool}</p>
              </div>
            </div> 
           
            <div className="col4">
              <div className="cardbox">
              <p>{cardBoxContent2[selectedPool][selectedTimePeriod]}</p>
                <p>{analyticsData[1]} {selectedPool}</p>
              </div>
            </div>
          </div>

        </div>
      </section>
  );
}
