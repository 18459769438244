import React, { useCallback, useRef, useState } from "react";
import { shortAddress, toLocale } from "../hooks/useContract";
import { CCol } from "@coreui/react";
import LazyLoad from "react-lazyload";
import { Alldata, Approve, GetReward, ReferralOuter, Stake, UsePoolData } from "../hooks/useContractData";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import dwcImage from "../Assets/images/(comp)defi-world-coin.gif";
import usdtImage from "../Assets/images/usdt-v1.gif";
import {
  PoolDWC180,
  PoolDWC30,
  PoolDWC360,
  PoolDWC7,
  PoolDWC90,
  PoolUSDT180,
  PoolUSDT30,
  PoolUSDT360,
  PoolUSDT7,
  PoolUSDT90,
} from "../config";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Yourstake = () => {
  const [dwcTabImage, setDwcTabImage] = useState(dwcImage);
  const [usdtTabImage, setUsdtTabImage] = useState(usdtImage);

  const handleChange2 = (event, newValue) => {
    setValue1(newValue);
    if (newValue === "DWC") {
      setValue(PoolDWC180);
      setPool(PoolDWC180);
      setDwcTabImage(dwcImage);
    } else {
      setValue(PoolUSDT180);
      setPool(PoolUSDT180);
      setUsdtTabImage(usdtImage);
    }
  }
  const selectRef = useRef(null);
  const select2Ref = useRef(null);
  const location = useLocation();
  const [value1, setValue1] = useState("DWC");
  const [Staked, setStaked] = useState(false);
  const [amount, setAmount] = useState(0);
  const [pool, setPool] = useState(PoolDWC360);
  const [refer, setrefer] = useState("");
  const [load, setLoad] = useState(true);
  const { referedBy, canRefer } = ReferralOuter();
  const { poolDetails } = UsePoolData()
  const { execute: getRewardData, Data } = GetReward();
  const { execute: ApproveToken } = Approve(pool);
  const { execute: Staking } = Stake(pool);
  const { allowance, HfgBalance } = Alldata(pool);

  const [value, setValue] = useState(PoolDWC360);
  const [day, setDay] = useState(0);
  const [percent, setPercent] = useState(0);
  const [coin, setCoin] = useState();

  const RewardCallback = useCallback(async () => {
    const selectedPool = value;
    const amount = select2Ref.current?.value;

    try {
      setLoad(false);
      const promist = await getRewardData?.(String(HfgBalance), selectedPool);
      setLoad(true);
    } catch (err) {
      setLoad(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [getRewardData,HfgBalance, value]);

  const StakeCallback = useCallback(async () => {
    try {
      // const amount = select2Ref.current?.value;
      if(parseFloat(HfgBalance) == 0) return toast.error("Insufficient balance")
      const amount = HfgBalance;

      setStaked(true);
      const promist = await Staking?.(String(amount), String(refer));

      setStaked(false);
    } catch (err) {
      setStaked(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [Staking, setStaked, refer]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ReferralId = queryParams.get('referrer');
    if (canRefer === true && referedBy !== 0) {
      setrefer(referedBy)
    } else {
      setrefer(ReferralId)
    }
  }, [location.search,canRefer, referedBy])

  const handleData = (value) => {
    const poolData = poolDetails.find(
      (item) => item.pool.toLowerCase() === value.toLowerCase()
    );
    setDay(poolData?.days);
    setPercent(poolData?.percent);
    setCoin(poolData?.coin);
  };

  const handleChange = (event, newValue) => {
    setPool(newValue);
    setValue(newValue);
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    if (newValue === "DWC") {
      setValue(PoolDWC360)
      setPool(PoolDWC360)

    } else {
      setValue(PoolUSDT360)
      setPool(PoolUSDT360)
    }
  };

  useEffect(() => {
    handleData(value);
  }, [value]);

  useEffect(() => {
    setAmount(HfgBalance)
  }, [HfgBalance])



  useEffect(() => {

    if (HfgBalance && parseFloat(HfgBalance) > 0) {
      RewardCallback();
    }

  }, [HfgBalance, value, RewardCallback]);


  return (
    <>
      <CCol className="cardsec" sm={12} style={{ margin: "0 auto" }}>

        <div className="">
          <a href="#!">
            <LazyLoad
              className="nftsvideo"
              once
              placeholder={
                <svg
                  className="loaderAnim"
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 0 0"
                >
                  <path
                    fill="#ffffff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              }
            >
              <div className="itembox mt-3">
                <div className="d-flex gifbox">
                  <img src={value1 === "DWC" ? dwcTabImage : usdtTabImage} alt="GIF" />
                </div>
              </div>


              <div className="stakebtnarea">
                <>

                  <div className="tabbar flex ">
                    <Tabs
                      value={value1}
                      onChange={handleChange1}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile={true}
                    >
                      <Tab label="DWC" value={"DWC"} />
                      <Tab label="USDT" value={"USDT"} />
                    </Tabs>
                  </div>
                  {value1 === "DWC" ?
                    <div className="tabbar flex justify-content-center" style={{ width: '100%' }}>
                      <Tabs
                        value={value}
                        ref={selectRef}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile={true}
                      >
                        {/* <Tab label="DWC-7" value={PoolDWC7} />
                        <Tab label="DWC-30" value={PoolDWC30} />
                        <Tab label="DWC-90" value={PoolDWC90} /> */}
                        <Tab label="DWC-180" value={PoolDWC180} />
                        <Tab label="DWC-360" value={PoolDWC360} />
                      </Tabs>
                    </div> :
                    <div className="tabbar flex" style={{ width: '100%' }}>
                      <Tabs
                        value={value}
                        ref={selectRef}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile={true}
                      >
                        {/* <Tab label="USDT-7" value={PoolUSDT7} />
                        <Tab label="USDT-30" value={PoolUSDT30} />
                        <Tab label="USDT-90" value={PoolUSDT90} /> */}
                        <Tab label="USDT-180" value={PoolUSDT180} />
                        <Tab label="USDT-360" value={PoolUSDT360} />
                      </Tabs>
                    </div>}
                  <div className="flex justify-content-center">
                    <p className="fs-6  tabbar flex" style={{ border: "1px solid #00ffff", padding: "8px", borderRadius: "5px" }}>Referral Wallet - {refer !== null ? shortAddress(refer) : "You do not have a referrer"}</p>
                  </div>
                  <div className="inputvalue">
                    <div className="leftwrap">
                      <div className="leftinfo">
                        <span className="labeltag">Aval Balance:</span>
                        <span className="labelspan">
                          <div style={{ marginRight: "4px" }}>
                            {toLocale(HfgBalance)} {coin}
                          </div>
                        </span>
                      </div>
                      <div className="leftinfo">
                        <span className="labeltag">Total Days:</span>
                        <span className="labelspan">
                          <div style={{ marginRight: "4px" }}>{day} DAYS</div>
                        </span>
                      </div>
                      <div className="leftinfo">
                        <span className="labeltag">Total Reward:</span>
                        <span className="labelspan d-flex align-items-center">
                          {load ? (
                            <>
                              {toLocale(Data)} DWC
                            </>
                          ) : (
                            <CircularProgress
                              size={"16px"}
                              style={{ marginTop: "5px" }}
                            />
                          )}
                        </span>
                      </div>
                      <div className="leftinfo">
                        <span className="labeltag">Per Day Rewards:</span>
                        <span className="d-flex align-items-center">
                          {load ? (
                            Data > 0 && day > 0 ? (
                              toLocale(Data / day)
                            ) : (
                              0
                            )
                          ) : (
                            <CircularProgress size={"16px"} />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="rightwrap">
                      <div className="rightinfo">
                        <span className="labelapr">APR</span>
                      </div>
                      <div className="rightinfo">
                        <span className="labelpercent">{percent}%</span>
                      </div>
                    </div>
                  </div>
                  <div className="inputbox">
                    {/* <div className="inputwrap">
                       <input
                        ref={select2Ref}
                        className="value"
                        type="number"
                        placeholder="Enter Amount"
                        value={amount}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            ".",
                          ];
                          if (!allowedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setAmount(e.target.value);
                          RewardCallback();
                        }}
                      /> 
                       <span
                        className="maxbtn"
                        onClick={() => setAmount(HfgBalance)}
                      >
                      {`${HfgBalance}  DWC`}
                      </span> 
                    </div> */}

                 {console.log('allowance dwcbalance flag : ',parseFloat(allowance),parseFloat(HfgBalance),parseFloat(allowance) > (parseFloat(HfgBalance) + 1000) )}

                    {parseFloat(allowance) > (parseFloat(HfgBalance) + 1000) ? (
  <button
    className="boxbtn"
    onClick={StakeCallback}
    disabled={Staked}
  >
    {Staked ? "Staking..." : `Stake ${coin}`}
  </button>
) : (
  <button
    className="boxbtn"
    onClick={() => {
      // if (amount > 0) {
        ApproveToken();
      // } else {
      //   toast.error("Please fill up amount");
      // }
    }}
  >
    Approve {coin}
  </button>
)}
                  </div>

                  <br />
                  <br />
                  <p className=" notearea ">
                    *<b>Note</b>- If you unstake DWC or USDT before maturity, --
                    of your staked amount will be deducted. Any pending rewards
                    at that time will not be processed. Ensure that you wait
                    until maturity to avoid these penalties.
                  </p>
                </>
              </div>
            </LazyLoad>
          </a>
        </div>
      </CCol>
    </>
  );
};

export default Yourstake;
