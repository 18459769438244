import { providers } from "ethers"
import Web3 from "web3"

// COIN  ADDRESS
export const CoinAddress = "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F";  // DWC TOKEN 
export const USDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";         // USDT TOKEN 

// ORACLE 
export const OracleAddress = "0x9640D63c4cA4d5f87B01F39Dc2b36697C7799f34"


// Mainnet POOLS
export const PoolDWC7 = "0xBb67Fa5ee0419FC930aE33F3aF915b33A62D9104"
export const PoolDWC30 = "0xf79D6209beae74f128A1248befE04C71026d91aa"
export const PoolDWC90 = "0xbB6d1e1dB8173eFc31a186A41b697B1B14eeb4f9"
export const PoolDWC180 = "0x17e3A69efA623e92EC8f5D09809B226740498f7B"
export const PoolDWC360 = "0x817cEA800b517c324a1323aAF327a6375c296985"

export const PoolUSDT7 = "0xe926f141eb759c1148f6240c567341fc32e7b7d0"
export const PoolUSDT30 = "0x0C4b8FFAFd2229551912e615A0b3E2B487a109Cc"
export const PoolUSDT90 = "0xCFFd2aE81d9336686FDA04cc99b7Ff7c8d0f378a"
export const PoolUSDT180 = "0x7aF84683B90A8d7B3Eaa7b0C9e04a87B0E541E54"
export const PoolUSDT360 = "0xAc4590C961BD0dA72150088e0E03d16e025cfEC3"


export const LockingPeriod = 7  //sec 7days for real

export const graphQlUrl =
  "https://api.studio.thegraph.com/query/60406/defiworld-stake-thegraph-m/version/latest";
// "https://api.studio.thegraph.com/query/60406/defiworld-stake-thegraph-t/version/latest";

export const transactionUrl = "https://polygonscan.com/tx/"

export const chainRPC =
  "https://polygon-rpc.com/f31fd1dab926f81b6236cd10e84ced895dd6ddf7";

export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

export const defaultNetwork = 137


// URL used in project 
export const url = "https://stake.defiworld.finance/";   // referral url
export const polygonchainRPC = "https://polygon-rpc.com"


// export const poolDetails = [
//     { pool: PoolDWC7, percent: 6.39, days: 7, coin: "DWC",label: "DWC-7" },
//     { pool: PoolDWC30, percent: 8.21, days: 30, coin: "DWC",label: "DWC-30" },
//     { pool: PoolDWC90, percent: 11.01, days: 90, coin: "DWC",label: "DWC-90" },
//     { pool: PoolDWC180, percent: 14.21, days: 180, coin: "DWC",label: "DWC-180" },
//     { pool: PoolDWC360, percent: 18.08, days: 360, coin: "DWC",label: "DWC-360" },
//     { pool: PoolUSDT7, percent: 4.25, days: 7, coin: "USDT",label: "USDT-7" },
//     { pool: PoolUSDT30, percent: 6.39, days: 30, coin: "USDT",label: "USDT-30" },
//     { pool: PoolUSDT90, percent: 8.21, days: 90, coin: "USDT",label: "USDT-90" },
//     { pool: PoolUSDT180, percent: 10.08, days: 180, coin: "USDT",label: "USDT-180" },
//     { pool: PoolUSDT360, percent: 12.01, days: 360, coin: "USDT",label: "USDT-360" },
// ]



// TEST POOLS
// export const PoolDWC7 = "0x832eF27fFdcDBFa860dccdD72a5B0174a6a8dBC6"
// export const PoolDWC30 = "0xF78a3697D214631F2fbb1aa37fD7a1226116C842"
// export const PoolDWC90 = "0x419FAb73c4610a58e04C37d9F472e9FDFc22418B"
// export const PoolDWC180 = "0x103007C13313BCf82bF463AC9ab5c0BAB4E0c5Ed"
// export const PoolDWC360 = "0x4E5eFCDF83b76191DF9E5aaBe7DFDE877Df4c11D"

// export const PoolUSDT7 = "0x1A558c1dB88c46B6e2Ff62EFF4AdbeE38e54b2a8"
// export const PoolUSDT30 = "0x79002730EE665410413AA1be32a56Ff1F226EA07"
// export const PoolUSDT90 = "0x0877683138ABc7596f1e2bd7EbD000b587F856B3"
// export const PoolUSDT180 = "0x99F73513249D43d3A98ab1442c493D9e2b9D2214"
// export const PoolUSDT360 = "0x3619F7df6a1cF94158D4e82d4c7aAc2c32AEFfA3"