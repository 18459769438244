import React,{useState,useEffect, useMemo, useCallback} from 'react'
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CopyToClipboard from "react-copy-to-clipboard";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import {shortAddress} from '../../hooks/useContract'
import { toast } from "react-hot-toast";
import createExcelFile from "../../hooks/dataSheetHandle";
import { Pagination } from "@mui/material";
import { CircularProgress } from "@mui/material";


const Daytable = ({
  TableData,
  isLoading,
  setIsLoading,
  majorTokenPool,
  timeRange,
  status,
  pool,
}) => {
  const [dwcToUsdtSwapRate, setDwcToUsdtSwapRate] = useState(null);
  const [tempTableData, setTempTableData] = useState(TableData);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const convertTimeStampToDate = (timestamp) => {
    timestamp = parseInt(timestamp);
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day}/${month}/${year}`;
  };

  const getSwapRate = async () => {
    let response = await fetch(
      "https://defi-price-node.vercel.app/get_SwapRate"
    );
    response = await response.json();
    response = response.data;
    // console.log('rateer : ',response[0] / 10000);
    setDwcToUsdtSwapRate(response[1] / 10000);
  };

  const notify = () => toast.success("Copy Successfully");

  const handleExcel = async () => {
    if (tempTableData.length === 0 && dwcToUsdtSwapRate) return;
    await createExcelFile(tempTableData, dwcToUsdtSwapRate, majorTokenPool);
  };

  useEffect(() => {
    getSwapRate();
  }, []);

  const handleStatusChange = useCallback(
    (bigData) => {
      if (status == null) {
        return bigData;
      }
      if (status == "active") {
        let currentTime = Math.floor(Date.now() / 1000);
        return bigData.filter(
          (data) => parseInt(data.expiryTimestamp) > currentTime
        );
      } else {
        let currentTime = Math.floor(Date.now() / 1000);
        return bigData.filter(
          (data) => parseInt(data.expiryTimestamp) < currentTime
        );
      }
    },
    [status, TableData]
  );

  const handlePoolChange = useCallback(
    (bigData) => {
      if (pool == null) {
        return bigData;
      } else {
        return bigData.filter((data) => data.pool === pool);
      }
    },
    [pool, TableData]
  );

  const handleTimeChange = useCallback(
    (bigData) => {
      if (timeRange.startTime === 0) {
        return bigData;
      }

      let startTime = timeRange.startTime;
      let endTime = timeRange.endTime;

      return bigData.filter(
        (data) =>
          parseInt(data.blockTimestamp) >= startTime &&
          parseInt(data.blockTimestamp) <= endTime
      );
    },
    [timeRange, TableData]
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return tempTableData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, itemsPerPage, tempTableData]);

  useEffect(() => {
    if (TableData?.length === 0) return;

    console.log("TableData 1: ", TableData[1]);

    console.log("timeRange: ", timeRange);
    console.log("status: ", status);
    console.log("pool: ", pool);

    let sortedData = handleStatusChange(TableData);
    // console.log("sortedData 0: ", sortedData);
    sortedData = handlePoolChange(sortedData);
    // console.log("sortedData 1: ", sortedData);
    sortedData = handleTimeChange(sortedData);
    // console.log("sortedData 2: ", sortedData);
    setTempTableData(sortedData);
  }, [TableData, timeRange, status, pool]);

  return (
    <div className="table-responsive">
      {!isLoading ? (
        <table className="table align-middle table-hover mb-0 dashboardTable">
         
          <thead className="table-light">
            <tr>
              <th className="text-center">Sr No</th>
              <th className="text-left">Wallet</th>
              <th className="text-left">Coin Staked Date</th>
              <th className="text-left">Stake Pool</th>
              <th className="text-left">Stake Expiry Date</th>
              <th className="text-left">Stake amount</th>
              <th className="text-left">Referral Wallet</th>
              <th className="text-left">Total reward in DWC</th>
              <th className="text-left">Total Reward in USDT</th>
            </tr>
          </thead>
         

          {currentTableData?.length > 0 &&
            currentTableData.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <CopyToClipboard style={{ cursor: "pointer" }} text={data.user}>
                  <td onClick={notify}>
                    {shortAddress(data.user)}
                    <CopyAllIcon
                      style={{
                        width: "17px",
                        marginLeft: "3px",
                        backgroundColor: "transparent",
                        color: "white",
                        padding: "0",
                      }}
                    />
                  </td>
                </CopyToClipboard>
                <td>{convertTimeStampToDate(data.blockTimestamp)}</td>
                <td>{data.pool}</td>
                <td>{convertTimeStampToDate(data.expiryTimestamp)}</td>
                <td>{parseFloat(data.amount).toFixed(4)}</td>
                <td>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      background: "transparent",
                    }}
                    target="blank"
                    to={`https://polygonscan.com/address/${data.referalWallet}`}
                  >
                    {shortAddress(data.referalWallet)}
                    <LaunchIcon
                      sx={{
                        width: "16px",
                        marginLeft: "3px",
                        color: "#2c70b3",
                      }}
                    />
                  </Link>
                </td>
                <td>{parseFloat(data.totalReward).toFixed(4)}</td>
                <td>
                  {(parseFloat(data.totalReward) * dwcToUsdtSwapRate).toFixed(
                    4
                  )}
                </td>
              </tr>
            ))}
       
        </table>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="success" />
        </div>
      )}

      <div
        style={{
          marginTop: "20px",
        }}
      >
        <Pagination
          count={Math.ceil(tempTableData.length / itemsPerPage)} // Ensure count is calculated correctly
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
          sx={{
            ".MuiPaginationItem-root": {
              color: "white", // Changes the text color
            },
          }}
        />
      </div>
      <Button
        onClick={() => handleExcel()}
        className="btnexcel"
        variant="contained"
        color="primary"
      >
        Export to Excel
      </Button>
    </div>
  );
};

export default Daytable