import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "sweetalert2/src/sweetalert2.scss";
import "../src/Assets/css/main.css";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import IncomeOverview from "./Components/IncomeOverview";
import { Toaster } from "react-hot-toast";
import { useAccount } from 'wagmi';
import AdminReport from "./Pages/Admin";
import AdminPanel from "./Pages/AdminPanel";
import AdmReport from "./Components/Admreport/Admreport";
import AdminReport2 from "./Components/Admreport/Admnreport2";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/account-overview" element={<IncomeOverview />} />
        <Route path="/2023-restrictedarea-admin" element={<AdminPanel />} />
        <Route path="/2023-restrictedarea-admin-report" element={<AdminReport />} />
        <Route path="/2023-restrictedarea-Admnreport2" element={<AdminReport2 />} />
        {/* <Route path="/2023-restrictedarea-Admreport" element={<AdmReport />} /> */}
      </Route>
    </Routes>
  );
};

const App = () => {
  const { address } = useAccount();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [address]);

  return (
    <>
      {isLoading ? (
        <div className='loaderMain'>
          <div className="loader2">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
        </>
      )}
    </>
  );
};

export default App;
