import logo from '../../Assets/images/logo.gif'
import './header.css'
import { ConnectButton, useAccountModal, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit'
import { shortAddress } from '../../hooks/useContract'
import { useAccount, useNetwork } from "wagmi";
import { defaultNetwork } from '../../config';
const Header = () => {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();
  const { address } = useAccount()
  const { chain } = useNetwork()
  const isCorrectNetwork = chain?.id === defaultNetwork


  return (
    <>
      <header >
        <div className="container   mx-auto ">
          <div className="row  ">
            <div className="headersec  ">
              <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  width="140"
                  height="140"
                  className="d-block "
                />
              </div>
              <div className="buttonGroup2 " id="demo" >
                {openConnectModal && (
                  <button className='btn1' color="btnMU mx-2" onClick={openConnectModal} type="button">
                    Connect
                  </button>
                )}
                {openAccountModal && (
                  <button className='btn1' color="btnMU mx-2" onClick={openAccountModal} type="button">
                    {shortAddress(address)}
                  </button>
                )}
                {isCorrectNetwork ? "":
                <>
                {openChainModal  && (
                  <button onClick={openChainModal} type="button" className='wnbtn mt-3'>
                    Wrong network
                  </button>
                )} 
                </>
               }

                {/* <ConnectButton className="btn1"
                  color="btnMU mx-2" showBalance={false} accountStatus={'address'} chainStatus={"icon"} /> */}

              </div>

            </div>
            <div className="logoDesktop">
              {/* <img src={logo} alt="logo" width="140" height="140" /> */}
              {/* <h2>DefiWorld</h2> */}
              <div className="headertext">
                <ul className="first-word">
                  <li>D</li>
                  <li>e</li>
                  <li>f</li>
                  <li>i</li>
                </ul>
                <ul className="second-word">
                  <li>W</li>
                  <li>o</li>
                  <li>r</li>
                  <li>l</li>
                  <li>d</li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-2 text-end">
              <div className="rightboxmenu">
                <ul className='balancebox list-unstyled m-0'>
                  <li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="before">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>


    </>
  )


  
}

export default Header