import React, { useEffect, useState } from 'react'
import { CRow, CCol } from '@coreui/react';
import claimedrewards from '../Assets/images/Icons/2claimed-reward.png'
import unclaimed from '../Assets/images/Icons/2unclaimed-reward.png'
import ammount from '../Assets/images/Icons/2stake-amount.png'
import totalreward from '../Assets/images/Icons/2referral-reward (2).png'
import { toLocale } from '../hooks/useContract';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { UsePoolData, UserCalling } from '../hooks/useContractData';
import { PoolDWC7, PoolUSDT7 } from '../config';
import { useGlobalData } from '../hooks/globalData';
import { formatEther } from 'viem';


export const Statistics = () => {
    const { data } = useGlobalData()
    const { poolDetails } = UsePoolData()
    const [value1, setValue1] = useState("DWC");
    const [selectedPool, setSelectedPool] = useState(PoolDWC7);
    const [coinname, setcoinname] = useState("DWC");

    const { stakeAmount = 0, claimableReward = 0, claimedReward = 0 } = UserCalling(selectedPool) || {};

    const handleChange = (event, newValue) => {
        setSelectedPool(poolDetails[newValue].pool);
        setcoinname(poolDetails[newValue].coin);
    };

    // const poolsWithStake = poolDetails.filter(poolData => UserCalling(poolData.pool)?.stakeAmount > 0);
    // const [initialSelection, setInitialSelection] = useState(null);

    // useEffect(() => {
    //     if (poolsWithStake.length > 0) {
    //         setInitialSelection(poolsWithStake[0].pool);
    //     }
    // }, [poolsWithStake]);

    // useEffect(() => {
    //     if (initialSelection !== null) {
    //         setSelectedPool(initialSelection);
    //     }
    // }, [initialSelection]);


    // Sum up the staked amounts
    const sumClaimAmount = data.filter(item => (item.poolAddress).toLowerCase() === selectedPool.toLowerCase());
    const stakedAmounts = sumClaimAmount.map(item => {
        return Number(formatEther(item["claimedAmount"]));
    });
    const totalClaimAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);


    const handleChange1 = (event, newValue) => {
        setValue1(newValue);
        setcoinname(newValue)
        if (newValue === "DWC") {
            setSelectedPool(PoolDWC7)
        } else {
            setSelectedPool(PoolUSDT7)
        }
    };


    return (

        <CCol md="12">
            <div className="tabbar flex ">
                <Tabs
                    value={value1}
                    onChange={handleChange1}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile={true}
                >
                    <Tab label="DWC" value={"DWC"} />
                    <Tab label="USDT" value={"USDT"} />
                </Tabs>
            </div>
            <div className="tabbar flex justify-content-center">
                {poolDetails.length > 0 &&
                    <Tabs
                        value={poolDetails.findIndex(poolData => poolData.pool === selectedPool)}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile={true}
                    >
                        {/* {poolDetails.filter(poolData => poolData.coin === value1).map((poolData, index) => (
                            <Tab key={index} value={value1 === "DWC" ? index : index + 5} label={`${poolData.label} Pool`} />
                        ))} */}

                        {poolDetails.filter(poolData => poolData.coin === value1 && (poolData.days > 179)).map((poolData, index) => (
                            <Tab key={index} value={value1 === "DWC" ? index : index + 5} label={`${poolData.label} Pool`} />
                        ))}

                    </Tabs>
                }
            </div>
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 4 }} className="justify-content-center">
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={totalreward} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Total Claimed Rewards</p>
                            <strong>{totalClaimAmount !== undefined ?
                                toLocale(totalClaimAmount)
                                : 0} DWC
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={claimedrewards} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Active Claimed Rewards</p>
                            <strong>
                                {toLocale(claimedReward)} DWC
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={unclaimed} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Unclaimed Rewards</p>
                            <strong>
                                {toLocale(claimableReward)} DWC
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={ammount} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Staked</p>
                            <strong>
                                {toLocale(stakeAmount)} {coinname}
                            </strong>

                        </div>
                    </div>
                </CCol>

            </CRow>
        </CCol>
    )
}