import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { useState } from "react";
import  { Alldata, Approve, GetReward,  ReferralOuter, Stake, UsePoolData } from "../hooks/useContractData";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { shortAddress, toLocale } from "../hooks/useContract";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@material-ui/core";
import {
  PoolDWC180,
  PoolDWC30,
  PoolDWC360,
  PoolDWC7,
  PoolDWC90,
  PoolUSDT180,
  PoolUSDT30,
  PoolUSDT360,
  PoolUSDT7,
  PoolUSDT90,
} from "../config";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModelPOP({ props, onClose }) {
  const selectRef = useRef(null);
  const select2Ref = useRef(null);
  const location = useLocation();
  const { referedBy, canRefer } = ReferralOuter();
  const {poolDetails} = UsePoolData()
  const [value1, setValue1] = useState("DWC");

  const [Staked, setStaked] = useState(false);
  const [amount, setAmount] = useState(0);
  const [pool, setPool] = useState(PoolDWC180);
  const [refer, setrefer] = useState("");
  const [load, setLoad] = useState(true);
  const [day, setDay] = useState(0);
  const [percent, setPercent] = useState(0);
  const [coin, setCoin] = useState();

  // Export Functions
  const { execute: getRewardData, Data } = GetReward();
  const { execute: ApproveToken } = Approve(pool);
  const { execute: Staking } = Stake(pool);
  const { allowance, HfgBalance } = Alldata(pool);
  const [newStake, setNewStake] = useState(props);
  const [value, setValue] = useState(PoolDWC180);

  const RewardCallback = useCallback(async () => {
    const selectedPool = value;
    const amount = select2Ref.current?.value;
    try {
      setLoad(false);
      const promist = await getRewardData?.(String(amount), selectedPool);
      setLoad(true);
    } catch (err) {
      setLoad(true);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [getRewardData, value]);

  const StakeCallback = useCallback(async () => {
    try {
      const amount = select2Ref.current?.value;
      setStaked(true);
      const promist = await Staking?.(String(amount), String(refer));
      setStaked(false);
      setNewStake(false);
      setAmount("");
    } catch (err) {
      setStaked(false);
      setNewStake(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [refer, Staking]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ReferralId = queryParams.get('referrer');
    if (canRefer === true && referedBy !== 0) {
      setrefer(referedBy)
    } else {
      setrefer(ReferralId)
    }
  }, [location.search, canRefer, referedBy])

  useEffect(() => {
    setNewStake(props);
  }, [props]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPool(newValue);
  };

  const handleData = (value) => {
    const poolData = poolDetails.find(
      (item) => item.pool.toLowerCase() === value.toLowerCase()
    );
    setDay(poolData.days);
    setPercent(poolData.percent);
    setCoin(poolData.coin);
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    if (newValue === "DWC") {
      setValue(PoolDWC180)
      setPool(PoolDWC180)

    } else {
      setValue(PoolUSDT180)

      setPool(PoolUSDT180)
    }
  };

  useEffect(() => {
    handleData(value);
  }, [value]);

  useEffect(() => {
    console.debug("HfgBalance", HfgBalance)
    setAmount(HfgBalance)
  }, [HfgBalance])

  useEffect(() => {
    // Check if there's a value in the amount and only then fetch reward data
    if (amount && amount > 0) {
      RewardCallback();
    }
  }, [amount, value, RewardCallback]);
  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={newStake}
      >
        <BootstrapDialogTitle
          className="zebclosewrap flex justify-content-between align-items-center"
          id="customized-dialog-title"
          onClose={onClose}
          sx={{ backgroundColor: "#000000d6", color: "#fff" }}
        >
          <h3 className="mb-0">Stake {coin}</h3>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ backgroundColor: "#000000d6", height: "auto" }}
        >
          <div className="stakebtnarea">
            <>
              <div className="tabbar flex justify-content-center">
                <Tabs
                  value={value1}
                  onChange={handleChange1}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                >
                  <Tab label="DWC" value={"DWC"} />
                  <Tab label="USDT" value={"USDT"} />
                </Tabs>
              </div>
              {value1 === "DWC" ?
                <div className="tabbar flex">
                  <Tabs
                    value={value}
                    ref={selectRef}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile={true}
                  >
                    {/* <Tab label="DWC-7" value={PoolDWC7} />
                    <Tab label="DWC-30" value={PoolDWC30} />
                    <Tab label="DWC-90" value={PoolDWC90} /> */}
                    <Tab label="DWC-180" value={PoolDWC180} />
                    <Tab label="DWC-360" value={PoolDWC360} />
                  </Tabs>
                </div> :
                <div className="tabbar flex">
                  <Tabs
                    value={value}
                    ref={selectRef}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile={true}
                  >
                    {/* <Tab label="USDT-7" value={PoolUSDT7} />
                    <Tab label="USDT-30" value={PoolUSDT30} />
                    <Tab label="USDT-90" value={PoolUSDT90} /> */}
                    <Tab label="USDT-180" value={PoolUSDT180} />
                    <Tab label="USDT-360" value={PoolUSDT360} />
                  </Tabs>
                </div>}
              <div className="flex justify-content-center">
                <p className="fs-6  tabbar flex" style={{ border: "1px solid #00ffff", padding: "8px", borderRadius: "5px" }}>Referral Wallet - {refer !== null ? shortAddress(refer) : "You do not have a referrer"}</p>
              </div>
              <div className="inputvalue">
                <div className="leftwrap">
                  <div className="leftinfo">
                    <span className="labeltag">Aval Balance:</span>
                    <span className="labelspan">
                      <div style={{ marginRight: "4px" }}>
                        {toLocale(HfgBalance)} {coin}
                      </div>
                    </span>
                  </div>
                  <div className="leftinfo">
                    <span className="labeltag">Total Days:</span>
                    <span className="labelspan">
                      <div style={{ marginRight: "4px" }}>{day} DAYS</div>
                    </span>
                  </div>
                  <div className="leftinfo">
                    <span className="labeltag">Total Reward:</span>
                    <span className="labelspan d-flex align-items-center">
                      {load ? (
                        <>
                          {toLocale(Data)} DWC
                        </>
                      ) : (
                        <CircularProgress
                          size={"16px"}
                          style={{ marginTop: "5px" }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="leftinfo">
                    <span className="labeltag">Per Day Rewards:</span>
                    <span className="d-flex align-items-center">
                      {load ? (
                        Data > 0 && day > 0 ? (
                          toLocale(Data / day)
                        ) : (
                          0
                        )
                      ) : (
                        <CircularProgress size={"16px"} />
                      )}
                    </span>
                  </div>
                </div>
                <div className="rightwrap">
                  <div className="rightinfo">
                    <span className="labelapr">APR</span>
                  </div>
                  <div className="rightinfo">
                    <span className="labelpercent">{percent}%</span>
                  </div>
                </div>
              </div>
              <div className="inputbox">
                <div className="inputwrap">
                  <input
                    ref={select2Ref}
                    className="value"
                    type="number"
                    placeholder="Enter Amount"
                    value={amount}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        ".",
                      ];
                      if (!allowedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      RewardCallback();
                    }}
                  />
                  <span
                    className="maxbtn"
                    onClick={() => setAmount(HfgBalance)}
                  >
                    Max
                  </span>
                </div>
                {(allowance > 0 && (
                  <>
                    <button
                      className="boxbtn"
                      onClick={StakeCallback}
                      disabled={Staked}
                    >
                      {Staked ? "Staking..." : `Stake ${coin}`}
                    </button>
                  </>
                )) || (
                    <button
                      className="boxbtn"
                      onClick={() => {
                        if (amount > 0) {
                          ApproveToken();
                        } else {
                          toast.error("Please fill up amount");
                        }
                      }}
                    >
                      Approve {coin}
                      <span></span>
                    </button>
                  )}
              </div>

              <br />
              <br />
              <p className=" notearea ">
                *<b>Note</b>- If you unstake DWC or USDT before maturity, --
                of your staked amount will be deducted. Any pending rewards
                at that time will not be processed. Ensure that you wait
                until maturity to avoid these penalties.
              </p>
            </>
          </div>

        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
