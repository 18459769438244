import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { useGraphData } from "../hooks/useGraph";
import TableContent from "./TableContent";
import StakeTable from "./StakeTable";
import TransTable from "./Trans";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function IncomeOverview() {
  const [value, setValue] = useState(0);
  const { data } = useGraphData();
  const location = useLocation()
  // console.log(data.referalLevelEarneds);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageId = queryParams.get('value');
    if (pageId) {
      setValue(Number(pageId))
    }
  }, [location.search])

  const combinedArray = data?.userPostStakedActions?.concat(data?.stakeRemoveds) ?? [];

  return (
    <>
      <div className="tablearea" style={{ marginBottom: "125px"  }}>
        <div className="container">
          <Box
            sx={{
              width: "100%",
              bgcolor: "#000",
              marginBottom: "50px",
              marginTop: "30px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              className="hello"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
            >
              <Tab sx={{ color: "#fff" }} label="My Stakes" />
              <Tab sx={{ color: "#fff" }} label="Reward & Unstake History" />
              <Tab sx={{ color: "#fff" }} label="Referral Income" />
            </Tabs>
          </Box>

          {value === 0 && <StakeTable props={data?.userStakedDatas} />}
          {value === 1 && <TransTable props={combinedArray} />}
          {value === 2 && <TableContent props={data?.userReferalRewards} />}
          
        </div>
      </div>

    </>
  );
}
