import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination } from "@mui/material";
import { useEffect, useState } from 'react';
import { shortAddress, toLocale } from '../hooks/useContract';
import { formatUnits } from 'viem';
import {  dateCon,  maturityDate } from '../config/helper';
import LaunchIcon from '@mui/icons-material/Launch';
import { CoinAddress,  transactionUrl } from '../config';
import { Link } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {  GetCoinByPool, UsePoolData } from '../hooks/useContractData';

export default function StakeTable(props) {
    const data = props.props
    const {poolDetails} = UsePoolData()

    const navigate = useNavigate()
    const [pagination, setPagination] = useState(0);
    const len = data?.length

    //  Code for pagination 
    const [indexOfLast, setindexOfLast] = useState(10)
    const [indexOfFirst, setindexOfFirst] = useState(0)

    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending and 'desc' for descending
    const [sortField, setSortField] = useState(''); // column/field to sort by

    function pagechange(event, value) {
        const currentPage = Number(value)
        const dataPerPage = 10

        const indexOfLastTodo = currentPage * dataPerPage;
        const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

        setindexOfFirst(indexOfFirstTodo)
        setindexOfLast(indexOfLastTodo)
    }
    const pageHandler = () => {
        const PageCount = Math.ceil(len / 10)
        if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
            setPagination(PageCount);
        }
    }

    useEffect(() => {
        pageHandler()
    }, [len])

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const numberSort = (a, b, direction) => {
        return direction === 'asc' ? a - b : b - a;
    };

    const stakeAmountSort = (a, b, direction) => {
        const valueA = formatUnits(a.amount, a.StakeToken.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6);
        const valueB = formatUnits(b.amount, b.StakeToken.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6);
        return direction === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
    };
    const dateSort = (a, b, direction) => {
        return direction === 'asc'
            ? new Date(Number(a.blockTimestamp)) - new Date(Number(b.blockTimestamp))
            : new Date(Number(b.blockTimestamp)) - new Date(Number(a.blockTimestamp));
    };

    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        if (data && sortField) {
            const newSortedData = [...data].sort((a, b) => {
                switch (sortField) {
                    case 'amount':
                        return stakeAmountSort(a, b, sortDirection);
                    case 'days':
                        return numberSort(GetCoinByPool(poolDetails,a.poolAddress).days, GetCoinByPool(poolDetails,b.poolAddress).days, sortDirection);
                    case 'blockTimestamp':
                        return dateSort(a, b, sortDirection);

                    default:
                        return 0;
                }
            });
            setSortedData(newSortedData);
        } else {
            setSortedData(data);
        }
    }, [sortField, sortDirection, data,poolDetails]);


    return (
        <>
            <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
                <form className="form">

                </form>
                <div className="backsidebtn" onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                </div>
            </div>
            <TableContainer component={Paper} sx={{ marginTop: "50px" }}>
                <Table aria-label="collapsible table">
                    <TableHead className='incometittle'>
                        <TableRow>
                            <TableCell >Sr No</TableCell>
                            <TableCell align="center" onClick={() => handleSort('amount')}>Stake Amount
                                {sortField === 'amount' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </TableCell>
                            <TableCell align="center">Total Reward</TableCell>

                            <TableCell align="center" onClick={() => handleSort('days')}>Pool
                                {sortField === 'days' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </TableCell>
                            <TableCell align="center" onClick={() => handleSort('blockTimestamp')}>Staked Date
                                {sortField === 'blockTimestamp' && sortDirection === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </TableCell>
                            <TableCell align="center" >Maturity Date
                            </TableCell>
                            <TableCell align="center">Tx Hash</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData ? sortedData.slice(indexOfFirst, indexOfLast).map((row, i) => (
                            < >
                                <TableRow className='incometable' >
                                    <TableCell component="th" scope="row">
                                        {i + indexOfFirst + 1}
                                    </TableCell>
                                    <TableCell align="center">{toLocale(formatUnits(row?.amount, GetCoinByPool(poolDetails,row?.poolAddress).coin === "DWC" ? 18 : 6))} {GetCoinByPool(poolDetails,row?.poolAddress).coin === "DWC" ? "DWC" : "USDT"}</TableCell>
                                    <TableCell align="center">{toLocale(formatUnits(row?.amount, GetCoinByPool(poolDetails,row?.poolAddress).coin === "DWC" ? 18 : 6) * (parseInt(row.percentageReturn) / 1000000) * Number(row?.DaysToMature) / 360)}</TableCell>
                                    <TableCell align="center">{row?.DaysToMature} Days</TableCell>
                                    <TableCell align="center">{dateCon(row.blockTimestamp)}</TableCell>
                                    <TableCell align="center">{maturityDate(row?.blockTimestamp, GetCoinByPool(poolDetails,row?.poolAddress).days)}</TableCell>
                                    <TableCell align="center"><Link style={{ textDecoration: "none", color: '#fff' }} to={transactionUrl + row?.transactionHash} target="blank" >
                                        <span className='d-flex align-items-center justify-content-center'>
                                            {shortAddress(row.transactionHash)}<LaunchIcon sx={{ width: "16px", marginLeft: "3px", color: "#2c70b3" }} />
                                        </span>
                                    </Link></TableCell>
                                </TableRow>

                            </>
                        )) : <></>}
                    </TableBody>
                </Table>
                {sortedData?.length > 0 ? "" :
                    <div className="nodata">
                        <h4>No Data Found</h4>
                    </div>
                }
            </TableContainer>
            {pagination <= 1 ?
                ''
                : <>
                    <Pagination className='myPagination' onChange={pagechange} count={pagination} color="secondary" />
                </>
            }
        </>

    );
}