import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useQueryAndSetAddress } from "./userAddress";

export const useGraphData = () => {
    const [data, setData] = useState([])
    const { address } = useQueryAndSetAddress()


    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {

                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{ 
                 
                           userStakedDatas (where: {user: "${address}" },orderBy: blockTimestamp, orderDirection: desc){
                            user
                            amount
                            DaysToMature
                            percentageReturn
                            poolAddress
                            blockTimestamp
                            transactionHash
                          }  
                 
                           userPostStakedActions (where:{user:"${address}" }, orderBy: blockTimestamp, orderDirection: desc){
                            user
                            status
                            stakeAmount
                            receiveAmount
                            DaysToMature
                            poolAddress
                            blockTimestamp
                            transactionHash
                           }
                     
                            userReferalRewards (where:{user:"${address}" }, orderBy: blockTimestamp, orderDirection: desc){
                            user
                            giver
                            level
                            status
                            stakeAmount  
                            DaysToMature
                            poolAddress
                            amount
                            blockTimestamp
                          }

                          stakeRemoveds(where: {Provider: "${address}"} orderBy: blockTimestamp, orderDirection: desc) {
                            Provider
                            Amount
                            poolAddress
                            receiveAmount
                            status
                            transactionHash
                            daysToMature
                            blockTimestamp
                          }
                         
                         }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;
                setData(res)
            }
            else {
                setData([])
            }

        } catch (err) {
            console.error(err)
        }
    }, [address])

    const fetchDetails = useCallback( async (newReferred) => {
        try {

                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{ 
                 
                          referalLevelEarneds(
                            where: {referal: "${address}" ,referred: "${newReferred.reffered}", poolAddress: "${newReferred.poolAddress}"}
                            orderBy: blockTimestamp
                            orderDirection: desc
                        ) {
                            transactionHash
                            stakeAmount
                            amount
                            poolAddress
                        }

                         }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;
                return res.referalLevelEarneds

        } catch (err) {
            console.error(err)
        }
    }, [address]);

    useEffect(() => {
        fetchGraphData();
        const interval = setInterval(fetchGraphData, 12000);
        return () => clearInterval(interval);
    }, [fetchGraphData]);

    return useMemo(() => ({ data, fetchDetails }), [data, fetchDetails]);
}


